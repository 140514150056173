import { render, staticRenderFns } from "./Gruplar.vue?vue&type=template&id=5b6e11c3&scoped=true&"
import script from "./Gruplar.vue?vue&type=script&lang=js&"
export * from "./Gruplar.vue?vue&type=script&lang=js&"
import style0 from "./Gruplar.vue?vue&type=style&index=0&id=5b6e11c3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b6e11c3",
  null
  
)

export default component.exports